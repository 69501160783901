import { Dropdown } from 'bootstrap';
import css from "./styles.scss";

function requireAll(r) {
  r.keys().forEach(r);
}

requireAll(require.context('../images/sprites/', true, /\.svg$/));

document.querySelectorAll('[data-sprite-url]').forEach(function(el){
    fetch(el.dataset.spriteUrl).then(res => {
        return res.text();
    }).then(data => {
        el.innerHTML = data;
    });
})

document.querySelectorAll('a[href^="//"],a[href^="http"]:not([href*="' + window.location.hostname + '"])').forEach(el=>{
    el.classList.add('external-link');
    el.setAttribute('target', '_blank');
})
document.querySelectorAll('a[href$=".xls"],a[href$=".xlsx"],a[href$=".doc"],a[href$=".docx"],a[href$=".doc"],a[href$=".pdf"]').forEach(el=>{
    el.setAttribute('target', '_blank');
})
